import React from "react";
import Layout from "../components/Layout";
import { graphql, useStaticQuery } from "gatsby";

export default function IndexPage() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Layout>
      <h3 className="font-weight-bold spanborder">
        <span>Contact</span>
      </h3>
      <form action="https://formspree.io/f/mqkwknwe" method="POST">
        <p className="mb-4">
          Please send your message to {data.site.siteMetadata.title}. We will
          reply as soon as possible!
        </p>
        <div className="form-group row">
          <div className="col-md-6">
            <input
              className="form-control"
              type="text"
              name="name"
              placeholder="Name*"
              required
            />
          </div>
          <div className="col-md-6">
            <input
              className="form-control"
              type="email"
              name="_replyto"
              placeholder="E-mail Address*"
              required
            />
          </div>
        </div>
        <textarea
          rows="8"
          className="form-control mb-3"
          name="message"
          placeholder="Message*"
          required
        ></textarea>
        <input className="btn btn-success" type="submit" value="Send" />
      </form>
    </Layout>
  );
}
